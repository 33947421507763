<template>
  <nav classs="ramen_nav">
    <router-link to="/">HOME</router-link>
    <router-link to="/about">ABOUT</router-link>
  </nav>
  <div class="top">
  <router-view/>
</div>
<footer>  
  <ul class="foot_link">
    <li class="foot_list">
      ホーム
    </li>
    <li class="foot_list">
      じこしょうかい
    </li>
    <li class="foot_list">
      <a href="">Portfolio</a>
    </li>
  </ul>
</footer>
</template>

<style>
@media screen and (min-width: 1280px) {
.top{
   background-image: url(https://ryotakobayashi.space/background-ramen.png);
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
  margin: 0;
}

nav{
  font-size: 3rem;
  padding-left: 3rem;
  background-color: rgb(199, 199, 198);}

nav a{
  font-family: "Rock 3D", system-ui;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
  color: #85f53b;
}
nav :hover{
  color: #78dd35;
  transition: 0.1s;
}

nav a.router-link-exact-active {
  color: #85f53b;
}
footer{
  margin: 0;
  font-size: 1vw;
  }

.foot_link{
  font-family:"Rock 3D", system-ui;;
  list-style: none;
  margin: 0 1vw;
  display: flex;
}
.foot_list{
  font-size: 2vw;
  color: #060606;
  padding-right: 3vw;
  margin: 0 auto;
}
.foot_list :hover{
  color:#69dc0a;
  transition: 0.1s;
}
}
@media screen and (max-width:768px){
  .top{
   background-image: url(https://ryotakobayashi.space/background-ramen.png);
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
  margin: 0;
}
  nav{
   
  font-size: 2rem;
  text-align: center;
  background-color: rgb(199, 199, 198);}

nav a{
  padding-left: 1rem;
  font-family: "Rock 3D", system-ui;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
  color: #424241;
}
nav a.router-link-exact-active {
  color: #424241;

}
footer{
  margin: 0;
  font-size: 1vw;
  }

.foot_link{
  font-family:"Rock 3D", system-ui;;
  list-style: none;
  margin: 0 1vw;
  display: flex;
}
.foot_list{
  font-size: 5vw;
  color: #060606;
  padding-right: 3vw;
  margin: 0 auto;
}
.foot_list :hover{
  color:#69dc0a;
  transition: 0.1s;
}
}
</style>
