import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/no1_ramen',
    name: 'No1-ramen',
    component: () => import(/* webpackChunkName: "no1-ramen" */ '../views/NooneView.vue')
  },
  {
    path: '/products/:productId',
    name: 'ProductView',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes  
})

export default router
