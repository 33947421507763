<template>
<div class="ramen">
  <div>
    <h2 class="main_h3"><span class="niigata">新潟</span>ラーメンランキング</h2>
    <ul class="ramen_ul">
      <li v-for="product in products.data" :key="product.id" class="ramen_li">
      <div class="ramen_wrapper">
        <div class="ramen_specifics">
          <router-link :to="getRamenLink(product.id)" class="jamp_Nopage">
        <img class="img_thumbnail" :src="getImageUrl(product.attributes.firstimage)" alt="商品画像">
        </router-link>
        <h3 class="ramen-name">{{ product.attributes.foodname }}</h3>
        <p class="ramen-update" v-if="product.attributes.indata">更新日時: {{product.attributes.indata}}</p>
        <p v-else></p>
        </div>
        <div class="ramen_comments">
      <h3 class="detail_comments">
        <router-link :to="getRamenLink(product.id)" class="jamp_Nopage">
          {{ product.attributes.foodsdetail ? (product.attributes.foodsdetail.length > 400 ? product.attributes.foodsdetail.substring(0, 400) + "...続きを見る" : product.attributes.foodsdetail) : '' }}
        </router-link>
      </h3>
        </div>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      products: { data: [] },
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get('https://kobayashiyashi.xyz/api/foods?populate=*');
        this.products = response.data;
      } catch (error) {
        console.error('データの取得に失敗しました', error);
      }
    },
    getImageUrl(imageData) {
      // 画像データからURLを構築する関数
      if (imageData && imageData.data) {
        return `http://kobayashiyashi.xyz${imageData.data.attributes.url}`;
      }
      // 画像が存在しない場合のデフォルトURLなどを指定
      return '';
    },
      getRamenLink(ramenId) {
    return ramenId === 1 ? `/no1_ramen` : `/products/${ramenId}`;
  },
  },
};
</script>
<style>
.jamp_Nopage{
  color: rgb(26, 26, 26);
  text-decoration: none;
  transition-duration: 0.25s;
}
.jamp_Nopage:hover {
  color: rgb(164, 164, 164);
  transition-duration: 0.25s;
}
.link{
  margin: 0;
}

.ramen_ul{
  padding-left: 0;
  padding-bottom: 10px;
}
/* Desktop */
@media screen and (max-width: 1920px) {
.main_h3{
  text-align: center;
  font-family: "Rampart One", sans-serif;
  font-size: 5vw;
  letter-spacing: -0.01em;
  color: yellow;
  margin-top: 0;
}
.niigata{
  font-feature-settings: "palt";
  font-size: 6vw;
  /* background-color: rgba(15, 15, 15, 0.844); */
  white-space: nowrap;
}
.ramen_wrapper{
  display: flex;
}
.ramen_specifics{
  width: 40%;
}
.ramen_comments{
  max-width: 70%;
  margin-top: 0;
}
.continue_detail_comments{
  opacity: 40%;
  font-size: 1rem;
}
.detail_comments{
  margin: 0 0 0 15px;
  font-family: Noto Sans JP;
  letter-spacing: -0.01em;
}
.ramen_li{
  list-style: none;
  /* フォント関係 */
  font-family: "Rampart One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1vw;
  color: #333;
  background-color: #ffffffe0;
  border-radius: 10px;
  padding: 1%;
  margin: 2.5% 15%;
}
.img_thumbnail{
  font-size: 5rem;
}
.ramen-name{
  margin: 0px;
}
.ramen-update{
  margin: 0px;
}
.img_thumbnail{
  max-width: 100%;
}
}
/* tablet */
@media screen and (max-width:1280px){
  .main_h3{
  text-align: center;
  font-family: "Rampart One", sans-serif;
  font-size: 5vw;
  letter-spacing: -0.01em;
  color: yellow;
}
.niigata{
  font-feature-settings: "palt";
  font-size: 6vw;
  white-space: nowrap;
}
.ramen_wrapper{
  display: flex;
}
.ramen_specifics{
  width: 40%;
}
.ramen_comments{
  max-width: 70%;
  margin-top: 0;
}
.continue_detail_comments{
  opacity: 40%;
  font-size: 1rem;
}
.detail_comments{
  margin: 0 0 0 15px;
  font-family: Noto Sans JP;
  letter-spacing: -0.01em;
}

.ramen_li{
  list-style: none;
  /* フォント関係 */
  font-family: "Rampart One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5vw;
  color: #333;
  background-color: #ffffffe0;
  border-radius: 10px;
  padding: 1%;
  margin: 3% 5%;
}
.img_thumbnail{
  font-size: 5rem;
}
.ramen-name{
  margin: 0px;
}
.ramen-update{
  margin: 0px;
}
.img_thumbnail{
  max-width: 100%;
}
}
/* tablet */
@media screen and (max-width:768px){
  .jamp_Nopage{
  color: rgb(26, 26, 26);
  text-decoration: none;
  transition-duration: 0.25s;
}
.jamp_Nopage:hover {
  color: rgb(164, 164, 164);
  transition-duration: 0.25s;
}
.link{
  margin: 0;
}
.ramen_ul{
  padding-left: 0;
  padding-bottom: 10px;
}
  .main_h3{
  text-align: center;
  font-family: "Rampart One", sans-serif;
  font-size: 5vw;
  letter-spacing: -0.02em;
  color: yellow;
}
.niigata{
  font-feature-settings: "palt";
  font-size: 6vw;
  /* background-color: rgba(15, 15, 15, 0.844); */
  white-space: nowrap;
}
.ramen_wrapper{
  display: flex;
}
.ramen_specifics{
  width: 40%;
}
.ramen_comments{
  max-width: 70%;
  margin-top: 0;
}
.continue_detail_comments{
  opacity: 40%;
  font-size: 1rem;
}
.detail_comments{
  margin: 0 0 0 15px;
  font-family: Noto Sans JP;
  letter-spacing: -0.01em;
}
.ramen_li{
  list-style: none;
  /* フォント関係 */
  font-family: "Rampart One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1vw;
  color: #333;
  background-color: #ffffffe0;
  border-radius: 10px;
  padding: 1%;
  margin: 2.5% 15%;
}
.img_thumbnail{
  font-size: 5rem;
}
.ramen-name{
  margin: 0px;
}
.ramen-update{
  margin: 0px;
}
.img_thumbnail{
  max-width: 100%;
}

.niigata{
  font-feature-settings: "palt";
  font-size: 6vw;
  white-space: nowrap;
}
.ramen_wrapper{
  display: flex;
}
.ramen_specifics{
  width: 40%;
}
.ramen_comments{
  max-width: 70%;
  margin-top: 0;
}
.continue_detail_comments{
  opacity: 40%;
  font-size: 1rem;
}
.detail_comments{
  margin: 0 0 0 15px;
  font-family: Noto Sans JP;
  letter-spacing: -0.01em;
}
.ramen_li{
  list-style: none;
  /* フォント関係 */
  font-family: "Rampart One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.8vw;
  color: #333;
  background-color: #ffffffe0;
  border-radius: 10px;
  margin: 10px 10px;}
.img_thumbnail{
  font-size: 5rem;
}
.ramen-name{
  margin: 0px;
}
.ramen-update{
  margin: 0px;
}
.img_thumbnail{
  max-width: 100%;
}
}
</style>
